
/* table 样式 */
/* .editor-custom table {
  border-top: 1px solid #ccc;
  border-left: 1px solid #ccc;
}
.editor-custom table td,
.editor-custom table th {
  border-bottom: 1px solid #ccc;
  border-right: 1px solid #ccc;
  padding: 3px 5px;
}
.editor-custom table th {
  border-bottom: 2px solid #ccc;
  text-align: center;
} */

/* blockquote 样式 */
/* .editor-custom blockquote {
  display: block;
  border-left: 8px solid #d0e5f2;
  padding: 5px 10px;
  margin: 10px 0;
  line-height: 1.4;
  font-size: 100%;
  background-color: #f1f1f1;
} */

/* code 样式 */
.editor-custom code[data-v-15dd6090] {
  display: inline-block;
  *display: inline;
  *zoom: 1;
  background-color: #f1f1f1;
  border-radius: 3px;
  padding: 3px 5px;
  margin: 0 3px;
}
.editor-custom pre code[data-v-15dd6090] {
  display: block;
}

/* ul ol 样式 */
/* .editor-custom ul, .editor-custom ol {
  margin: 10px 0 10px 20px;
} */
.editor-custom ul li[data-v-15dd6090] {
  list-style-type: disc !important;
}
.editor-custom ol li[data-v-15dd6090] {
  list-style-type: decimal !important;
}
.html-content[data-v-15dd6090]{
white-space: pre-wrap;
}
.html-content[data-v-15dd6090] img{
  max-width: 100%;
}

